.container {
  background-color: #000;
  height: 100%;
  width: 100%;
  padding: 0;
  position: relative;
  overflow: hidden;
}

.ourMissionContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 50px 16px 0px;
  max-width: 623px;
  margin: 0 auto;
  color: #fff;
  /* Subittle */
  font-family: "staatliches";
}

.ourMissionData {
  display: flex;
  flex-direction: column;
  gap: 13px;
}

.ourMissionTopDataTitle {
  font-size: 48px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.48px;
}

.ourMissionTopDataDescription {
  color: #fff;
  font-family: "roboto-regular";
  font-size: 22px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.11px;
}

.ourMissionDescription {
  font-feature-settings: "dnom" on;
  font-family: "roboto-regular";
  font-size: 18px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.ourMissionData a {
  width: fit-content;
  border-radius: 13px;
  background: #b22234;
  padding: 16px 24px;
  color: #fff;
  font-family: "roboto-medium";
  font-size: 24px !important;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}

.dividedFaceImage {
  object-fit: contain;
  width: 379px;
  height: 290px;
  margin: 0 auto;
}

.dividedFaceBg {
  display: none;
}

@media screen and (min-width: 1312px) {
  .ourMissionContainer {
    max-width: 1280px;
    margin: 0 auto;
    padding: 95px 0 200px;
  }

  .ourMissionData {
    max-width: 602px;
    margin-right: auto;
    gap: 16px;
  }

  .dividedFaceImage {
    display: none;
  }

  .ourMissionTopDataDescription {
    font-size: 32px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 44px;
    letter-spacing: 0.16px;
  }

  .ourMissionDescription {
    font-size: 22px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
  }

  .dividedFaceBg {
    display: flex;
    position: absolute;
    bottom: 0;
    right: -50px;
  }
}
