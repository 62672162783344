@media screen and (max-width: 767px) {
  .form,
  .form2,
  .countdown-section2 {
    /* border-radius: 0 !important;
    box-shadow: none !important; */

    /* width: 90vw !important; */
    height: fit-content;
  }

  .form {
    position: static;
    top: 200px !important;
    height: fit-content;
  }

  .form2 {
    width: 85vw !important;
    position: static;
    margin-top: 3rem;
    /* top: -300px !important; */
    height: fit-content;
    margin-bottom: 30px
  }

  .form-update{
    /* width: 85vw !important; */
    position: static;
    /* margin-top: 3rem; */
    /* top: -300px !important; */
    height: fit-content;
  }

  .title-page {
    position: relative;
    top: 40px;
    left: 0px;
    width: 100vw;
    height: fit-content !important;
    font-size: 1.8rem !important;
  }

  .signature-pad {
    width: 80vw !important;
  }

  .signature-section {
    width: 100vw !important;
    padding: 0px !important;
  }

  .container-sign {
    position:  relative !important;
    width: 100vw !important;
    margin: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    height: fit-content !important;
    /* left: 0px !important; */
    /* background-color: red !important; */
    /* left: -16% !important; */
  }

  .countdown-section2 {
    display: none !important;
    position: relative;
    bottom: 30px;
    left: 20px;
    /* width: 100vw !important; */
  }
  .countdown-title2 {
    font-size: 1rem !important;
  }
  .countdown-label2 {
    font-size: 0.8rem !important;
    padding-bottom: 0px;
  }
  .countdown-container2 {
    padding: 0 !important;
    padding-top: 10px !important;
  }

  .container2 {
    /* background-color: red; */
    width: 85vw !important;
    margin: 0 auto;
    position: relative;
    top: 60px;
    border-radius: 0px !important;
    /* bottom: 0px; */
  }

  .button-update-profile {
    border-radius: 0px !important;
  }
/* 
  .posDiv {
    margin-top: -14rem;
    width: 90vw;
  } */
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}


.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  text-align: center;
}

.container-1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  text-align: center;
}

.form, .form2, .form-update {
  background-color: rgba(240, 240, 240, 0.945);
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 450px;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 20px;
  border-radius: 12px;
  margin-bottom: 30px;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  text-align: center;
}

.title-sign-rs {
  font-size: 1.4rem !important;
  margin-bottom: 1.5rem !important;
  max-width: 100vw !important;
  text-align: center !important;
}

.form2{
  margin-top: 0rem !important;
}

.subrors{
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 450px;
  width: 100%;

  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 20px;
  border-radius: 12px;
}

.form2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 450px;
  width: 100%;

  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 20px;
  border-radius: 12px;
}
.form-group {
  position: relative;
  margin-bottom: 1rem;
}

.form-group input {
  max-width: 600px;
  width: 100%;
  padding: 0.5rem 0;
  border: none;
  border-bottom: 1px solid #000;
  background-color: transparent;
  color: #000;
  font-size: 1.5rem;
}

.form-group input:focus {
  outline: none;
  border-bottom: 1px solid #000;
}

.form-group label {
  position: absolute;
  bottom: calc(15% + 0.25rem);
  left: 0;
  font-size: 1rem;
  color: #000;
  transition: all 0.2s ease-in-out;
  pointer-events: none;
}

.form-group input:not(:placeholder-shown) + label,
.form-group input:focus + label {
  transform: translateY(-2.3rem);
  font-size: 0.8rem;
  color: #000;
}

.signature-box {
  align-items: center;
  border: 1px solid black;
  border-radius: 10px;
  display: flex;
  font-family: "Beth Ellen";
  font-size: 1rem;
  height: 150px;
  justify-content: center;
  margin-bottom: 2rem;
  width: 80%;
  user-select: none;
  -webkit-user-select: none;
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  width: 75%;
  max-width: 500px;
}

.button-container button {
  font-size: 1.1rem;
  font-weight: 600;
  padding: 0.5rem 1rem;
  border: 1px solid #000;
  border-radius: 1rem;
  background-color: #000;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
}

.button-sign:hover {
  background-color: #000;
  color: #fff;
  opacity: 80% !important;
}

.button-sign {
  width: 5.3rem;
  margin-left: 0.5rem;
}
.button-upload {
  background-color: transparent !important;
  color: #000 !important;
}

.button-upload:hover {
  background-color: #000 !important;
  color: #fff !important;
}

.signature-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
}

.signature-form h1 {
  font-size: 3rem;
  margin-bottom: 2rem;
}

.signature-form input {
  font-family: "Beth Ellen";
  font-size: 2.5rem;
  width: 100%;
  margin-bottom: 2rem;
  padding: 0.5rem;
  border: none;
  background-color: transparent;
  border-bottom: 1px solid #000;
  transition: border-color 0.2s ease-in-out;
}

.signature-form input:focus {
  outline: none;
  border-color: #555;
}

.signature-preview {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  width: 100%;
  min-height: 150px;
  border: 1px solid #000;
  font-size: 3rem;
  font-family: "Beth Ellen", cursive;
  line-height: 1;
}

.signature-preview p {
  margin: 0;
  padding: 0;
}

.countdown-section2 {
  background-color: #000;
  color: #fff;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 450px;
  margin-top: 2rem !important;
  margin: 0 auto;
}

.countdown-container2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
}

.countdown-title2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.countdown2 {
  display: flex;
  margin-bottom: 1rem;
}

.countdown-item2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 1rem;
}

.countdown-number2 {
  font-size: 1.5rem;
  font-weight: bold;
}

.countdown-label2 {
  font-size: 1rem;
}

.button-update-profile {
  font-size: 1.1rem;
  font-weight: 600;
  padding: 0.5rem 1rem;
  border: 1px solid white !important;
  border-radius: 1rem;
  background-color: white !important;
  color: black;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
}

.button-register {
  font-size: 1.1rem;
  font-weight: 600;
  padding: 0.5rem 1rem;
  border: 1px solid #000;
  border-radius: 1rem;
  background-color: #000;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  margin: 5px;
}

.register-button-container {
  display: block;
  justify-content: center;
  margin-top: 2rem;
  width: 90%;
  max-width: 500px;
}

.message-red {
  color: red;
  margin: 3px;
}

.image {
  cursor: pointer;
}

.image:hover {
  webkit-filter: blur(4px); /* Chrome, Safari, Opera */
  filter: blur(4px);
  cursor: pointer;
}
.signature-section {
  background-color: #fff;
  color: #000;
  padding: 20px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("https://sfo3.digitaloceanspaces.com/ww-space/doi/doi_assets/homePen.png");
  background-size: cover;
  background-position: center calc(70% - 20%);
}

.signature-section-g {
  background-color: #000;
  color: #000;
  padding: 20px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

}

.container-sign {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  /* background-color: red; */
}

.my-custom-class .file-uploader {
  min-width: 200px;
}

.imagePreview{
  margin-bottom: 1rem;
}