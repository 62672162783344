.baseGrid {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: transform 0.5s, opacity 0.5s;
}
.doi {
  position: absolute;
  transition: transform 0.5s, opacity 0.5s;
}
.doifilter {
  position: absolute;
  image-rendering: "crisp-edges";
  transition: transform 0.5s, opacity 0.5s;
}
.animationBlock {
  background-color: black;
  opacity: 0.3;
  grid-row: 1;
  grid-column: 1;
  position: relative;
}

i {
  display: inline-block;
  font-style: normal;
  position: relative;
}

i.arrow {
  /* top: 2pt; Uncomment this to lower the icons as requested in comments*/
  width: 0.7em;
  height: 0.7em;
  border-right: 0.2em solid black;
  border-top: 0.2em solid black;

  transform: translate(-50%, -50%) rotate(45deg);
}

.zoomedGrid {
  position: absolute;
  /* top: 0;
  left: 0;*/
  opacity: 0;
  transition: opacity 0.5s;
}

.baseGridBox {
  border: 0px;
  border-style: dashed;
  /*border-color: "rgba(30, 30, 30, 0.30)";*/
  transition: border-style 0.1s;
  transition: border-color 0.1s;
  transition: background-color 0.1s;
  transition: border 0.1s;
  color: transparent;
}
.selectionGrid {
  transition: transform 0.5s;
}

.baseGridBoxhighlighted {
  border: 3px;
  border-style: dashed;
  border-color: black;

  background-color: rgba(30, 30, 30, 0.7);
  transition: border-style 0.1s;
  transition: border-color 0.1s;
  transition: background-color 0.1s;
  transition: border 0.1s;
  color: white;
}

.baseGrid.clicked {
  transform-origin: center;
  transform: scale(300);
  opacity: 0;
  z-index: -1;
  position: absolute;
  animation: zoomIn 1s;
  transition: transform 0.5s;
  /* display: none; */
}
.doi.clicked {
  transform-origin: center;
  transform: scale(300);
  opacity: 0.1;
  z-index: 0;
  position: absolute;
  animation: zoomIn 1s;
  /* display: none; */
}

.doifilter.clicked {
  transform-origin: center;
  transform: scale(300);
  opacity: 0.2;
  z-index: 0;
  position: absolute;
  animation: zoomIn 1s;
  /* display: none; */
}

.baseGrid.clicked + .zoomedGrid {
  opacity: 1;
  transform-origin: center;
  animation: fadeIn 0.4s;
  transition: transform 0.5s;
}
.non-floating-button {
  background-color: white;
  color: black;
  font-weight: 600;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  z-index: 100;
  border-radius: 12px;
}

.audio-player {
  width: fit-content;
  display: flex;
  align-items: center;
}

.play-pause-button {
  background: white;
  padding: 5px 12px;
  border-radius: 12px;
  border: none;
  font-size: 20px;
  color: #333;
  cursor: pointer;
}

.tooltip {
  position: fixed;
  bottom: 68px;
  right: 110px;
  background-color: #333;
  color: #fff;
  padding: 10px;
  border-radius: 4px;
  font-size: 14px;
  z-index: 999;
}

.tooltip p {
  margin: 0;
  margin-bottom: 5px;
}

.tooltip-close-button {
  background: none;
  border: none;
  color: #fff;
  font-size: 12px;
  cursor: pointer;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes zoomIn {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(300);
  }
}

.accesibility2{
  display: none;
}

.find2{
  display: none;
}

@media screen and (max-width: 768px) {
  .header {
    /* background-color: red !important; */
    font-size: 12px !important;
    margin: 5px;
  }
  .accesibility {
    display: none !important;
  }
  .accesibility2{
    display: block !important
  }
  .find{
    display: none !important;
  }
  .find2{
    display: block !important;
  }
  .btn-header-find{
    width: 35vw !important;
  }
}
@keyframes shift-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes shift-right {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes shift-up {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}

@keyframes shift-down {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100%);
  }
}
