.container {
  height: 100%;
  width: 100%;
  padding: 0;
  background-color: #000;
}

.freedomContainer {
  width: 100%;
  max-width: 623px;
  margin: 0 auto;
  padding: 50px 16px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.freedomTopData {
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: #fff;
}

.freedomTopDataTitle {
  font-family: "staatliches";
  font-size: 40px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
  letter-spacing: 0.4px;
  max-width: 181px;
}
.freedomTopDataDescription {
  font-family: "roboto";
  font-size: 22px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 136.364% */
  letter-spacing: 0.11px;
}

.freedomTopData a {
  width: fit-content;
  border-radius: 13px;
  background: #b22234;
  padding: 16px 24px;
  color: #fff;
  font-family: "roboto-medium";
  font-size: 24px !important;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}

.freedomImageContainer {
  display: flex;
  flex-direction: column;
  gap: 71px;
}

.freedomImageText {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 369px;
  margin: 0 auto;
}

.freedomImageText p {
  color: #ccc3c3;
  font-family: "roboto-extralight";
  font-size: 23px !important;
  font-style: italic;
  font-weight: 200;
  line-height: 124.688%;
}

.freedomOpenQuote {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.freedomClosingQuote {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.johnHancockImage {
  height: 370px;
  width: 100%;
  object-fit: cover;
}

@media screen and (min-width: 1312px) {
  .freedomContainer {
    max-width: 1280px;
    margin: 0 auto;
    padding: 100px 0;
    flex-direction: row;
    gap: 50px;
    justify-content: space-between;
    align-items: center;
  }

  .freedomTopData {
    gap: 12px;
    max-width: 768px;
  }

  .freedomTopDataTitle {
    max-width: none;
    font-size: 48px !important;
    font-weight: 400;
    letter-spacing: 0.48px !important;
  }

  .freedomTopDataDescription {
    font-size: 32px !important;
    font-weight: 400;
    line-height: 44px !important;
    letter-spacing: 0.16px;
  }

  .johnHancockImage {
    width: 301px;
    height: 300px;
  }
}
