.container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  /* background-color: red; */
}

.br {
  display: none;
}

.reachoutrs {
  display: flex;
  flex-direction: column-reverse;
  margin-top: -1rem;
  margin-bottom: 2rem;
}

.container2 {
  /* background-color: red; */
  justify-content: space-between !important;
}

.sec-size {
  position: absolute;
  top: 59px;
}

.sec-size-no-navbar {
  position: absolute;
}

.video-container {
  text-align: center;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3rem;
  margin-top: 1rem;
  height: fit-content;
  max-width: 90vw;
}

.video {
  max-width: 50%;
  width: 80vw;
  height: auto;
}

.floating-button-pay {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #b22234;
  color: white;
  font-weight: 600;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  z-index: 100;
  border-radius: 12px;
}

.container3 {
  /* background-color: red; */
  width: 55% !important;
  /* background-color: yellow; */
  /* height:30vh !important */
}

.container4 {
  width: 37vw;
  margin-top: 1.5rem;
}

.button-container {
  display: flex;
}

.square {
  width: 12vw;
  height: fit-content;
  min-height: 19vh;
  /* max-height: 25vh; */
  background-color: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(20px);
  margin: 10px;
  color: white;
  border-radius: 12px;
  padding: 30px 30px;
  border: 2px solid transparent;
}

.square2 {
  width: 30%;
  height: 17vh;
  max-height: 19vh;
  /* min-height: 19vh; */
  /* max-height: 25vh; */
  background-color: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(20px);
  color: white;
  border-radius: 12px;
  padding: 20px 30px;
  border: 2px solid transparent;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.square.active {
  border-color: #b22234; /* Borde dorado */
}

.square2.active2 {
  border-color: #b22234; /* Borde dorado */
}
.square.active2:focus {
  border-color: #b22234; /* Borde dorado */
}

.price {
  margin-bottom: 0.6rem;
  font-size: 1.7rem;
  font-weight: 600;
}

.price2 {
  margin-left: -2rem;
  font-size: 1.2rem;
  font-weight: 600;
}
.text {
  font-weight: 600;
}

.sub {
  margin-top: 10px;
  font-size: 0.9rem;
}

.sub2 {
  font-size: 1.2rem;
  font-weight: 500;
  /* margin-left: -2rem; */
}

.purchase-button {
  background-color: #cd7f32;
  flex-basis: calc(30% - 40px);
  width: 100%;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px;
  margin: 20px 5px; /* Margen para separar los botones */
  cursor: pointer;
  font-weight: bold;
  font-size: 1.2rem;
  text-align: center;
}

.purchase-button2 {
  flex-basis: calc(30% - 40px);
  width: 100%;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  font-weight: bold;
  font-size: 1.2rem;
  text-align: center;
}

.purchase-button3 {
  flex-basis: calc(30% - 40px);
  width: 100%;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  font-weight: bold;
  font-size: 1.2rem;
  text-align: center;
}

.cancel-button {
  flex-basis: calc(10% - 10px);
  width: 100%;
  border: none;
  border-radius: 5px;
  padding: 10px;
  margin: 20px 5px; /* Margen para separar los botones */
  cursor: pointer;
  font-weight: bold;
  font-size: 1.2rem;
  text-align: center;
}

.cancel-button2 {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(20px);
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  font-weight: 500;
  color: white;
  font-size: 1.2rem;
  text-align: center;
}

.signature-overlay {
  margin-top: -2rem;
}

.signature {
  pointer-events: auto; /* Habilita la interacción con la firma */
}

.preview-img {
  width: 100%;
  display: block;
  margin-top: 1rem;
  border-radius: 12px;
  height: 35vh;
  width: 37vw;
  object-fit: cover;
  margin-bottom: -1rem;
}

.preview-img2 {
  width: 55vw !important;
  display: block;
  margin-top: 1rem;
  border-radius: 12px;
  height: 35vh;
  width: 37vw;
  object-fit: cover;
  margin-bottom: -1rem;
}

.preview-img3 {
  width: 55vw !important;
  display: block;
  margin-top: 1rem;
  border-radius: 12px;
  height: auto;
  width: 37vw;
  object-fit: cover;
  margin-bottom: -1rem;
}

.svg-container {
  height: 25vh !important;
  max-height: 25vh !important;
  /* width: ; */
}

.is-mobile {
  display: none;
}

.div-first2 {
  margin-top: 1rem !important;
}

@media screen and (max-height: 700px) {
  .title-size {
    /* margin-top: 5rem !important; */
    font-size: 2rem !important;
  }
  .text {
    font-size: 0.8rem !important;
  }
  .price {
    font-size: 0.9rem !important;
  }
  .sub {
    font-size: 0.8rem !important;
  }
  .most-popular {
    font-size: 0.8rem !important;
  }
}

@media screen and (max-width: 770px) {
  .div-first2 {
    margin-top: 2rem !important;
  }
  .sub2 {
    font-size: 0.6rem;
  }
  .price2 {
    font-size: 0.6rem;
    margin-right: 2.5rem;
    padding-left: 1rem;
  }
  .square {
    width: 80vw !important;
    max-height: 10vh !important;
  }
  /* .price{
    font-size: 1rem !important;
  }
  .text{
    font-size: 0.8rem !important;
  }
  .sub{
    font-size: 0.7rem !important; */
  /* } */
  .square2 {
    max-height: fit-content !important;
    width: 75vw !important;
    margin-top: 1rem;
  }
  .most-popular {
    width: 123% !important;
    border-radius: 12px 12px 0px 0px !important;
    /* left: 0px !important; */
    font-size: 0.7rem !important;
    position: relative !important;
    /* right: 115px !important; */
    top: -5px !important;
  }
  .most-popular-2 {
    width: 40% !important;
    /* border-radius: 12px 0px 12px 0px !important; */
    /* left: 0px !important; */
    font-size: 0.7rem !important;
    position: relative !important;
    left: -100px !important;
    top: 25px !important;
  }
  .preview-img {
    width: 80vw !important;
  }
  .preview-img3 {
    width: 75vw !important;
  }

  .titleth {
    width: 75vw !important;
  }

  .subtitleth {
    width: 75vw !important;
  }

  .container3 {
    width: 75vw !important;
  }
  .gif-div {
    width: 85vw !important;
  }
  .gif {
    width: 85vw !important;
  }

  .container4 {
    width: 80vw !important;
  }
}

@media screen and (max-width: 1100px) and (min-width: 770px) {
  .sub2 {
    font-size: 0.6rem;
  }
  .price2 {
    font-size: 0.6rem;
    margin-right: 2.5rem;
    padding-left: 1rem;
  }
  .square {
    width: 22vw !important;
    max-height: 10vh !important;
  }
  .price {
    font-size: 1rem !important;
  }
  .text {
    font-size: 0.8rem !important;
  }
  .sub {
    font-size: 0.7rem !important;
  }
  .square2 {
    /* max-height: 3.5rem; */
  }

  .titleth {
    width: 90vw !important;
  }
  .subtitleth {
    width: 90vw !important;
  }
}

@media screen and (max-width: 1700px) and (min-width: 1100px) {
  .sub2 {
    font-size: 0.8rem;
  }
  .price2 {
    font-size: 0.8rem;
    margin-right: 2.5rem;
    padding-left: 1rem;
  }
  .square {
    width: 16vw !important;
  }
  .preview-img {
    width: 51vw !important;
  }
  .square2 {
    /* max-height: 3.5rem; */
  }
  .container4 {
    width: 100vw !important;
  }
  .button-container2 {
    margin: 0 auto !important;
    width: fit-content !important;
    text-align: center !important;
    height: fit-content !important;
  }
  .cancel-button2 {
    width: 51vw !important;
    margin-right: -10px !important;
  }
  .purchase-button2 {
    width: 51vw !important;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .sec-size {
    /* position: absolute !important; */
    top: 0px !important;
    justify-content: flex-start !important;
    padding: 30px !important;
  }

  .sec-size-no-navbar {
    /* position: absolute !important; */
    top: 0px !important;
    justify-content: flex-start !important;
    padding: 30px !important;
  }

  .title-size {
    margin-top: -2rem;
    font-size: 1.6rem !important;
  }
  .container {
    height: fit-content !important;
    margin-top: 0.5rem !important;
    margin-bottom: 1rem !important;
  }
  .container2 {
    margin-top: 2rem !important;
  }
  .sub-size {
    margin-top: 0.3rem !important;
    font-size: 0.8rem !important;
  }
  .size1 {
    font-size: 1.3rem !important;
  }

  .size2 {
    font-size: 1.5rem !important;
  }

  .size3 {
    font-size: 2rem !important;
  }
  .div-first {
    margin-top: 2rem !important;
    position: relative !important;
    top: 0px !important;
  }
  .div-first2 {
    margin-top: 2rem !important;
    position: relative !important;
    top: 0px !important;
  }
  .div-first3 {
    margin-top: 4rem !important;
  }
  .subro {
    margin-top: -1rem !important;
    margin-bottom: 0px !important;
  }

  .reachout {
    margin-top: -2rem !important;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start !important;
    justify-content: flex-end !important;
    /* height: 40vh; */
  }
  .containerrs {
    flex-direction: column-reverse !important;
  }
  .sub2 {
    font-size: 0.9rem !important;
  }
  .price2 {
    font-size: 0.9rem !important;
    margin-right: 0rem !important;
    padding-left: 0rem !important;
  }
  .square {
    width: 80vw !important;
    border: 2px solid transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 12vh !important;
    min-height: 10vh !important;
    margin-bottom: 0px !important;
    /* height: 10vh !important; */
  }
  .sub {
    font-size: 0.8rem !important;
    margin: 0 auto !important;
  }
  .price {
    font-size: 0.8rem !important;
    margin: 0 auto !important;
  }
  .text {
    font-size: 0.8rem !important;
    margin: 0 auto !important;
  }
  .preview-img {
    width: 80vw !important;
    height: 20vh !important;
  }
  .square2 {
    max-height: 5rem !important;
    width: 100% !important;
    /* margin: 0 auto; */
    height: fit-content !important;
    margin-top: 0.3rem;
    margin-bottom: 0rem !important;
  }
  .preview-img2 {
    width: 100% !important;
    height: 100% !important;
    /* margin-bottom: 10rem !important; */
  }
  .preview-img3 {
    width: 100% !important;
    height: 100% !important;
    /* margin-bottom: 10rem !important; */
  }
  .button-container {
    display: flex !important;
    flex-direction: column-reverse !important;
    align-items: center;
  }
  .button-container2 {
    height: fit-content !important;
  }
  .purchase-button {
    width: 80vw !important;
    margin: 0 auto !important;
  }
  .cancel-button {
    width: 80vw !important;
    margin: 0 auto !important;
    margin-top: 1rem !important;
    margin-left: 10px !important;
  }
  .container2 {
    /* margin: 0 auto !important */
    position: relative !important;
    top: 10px !important;
    margin-bottom: 0px !important;
  }
  .purchase-button2 {
    z-index: 9 !important;
    position: fixed !important;
    bottom: 0px !important;
    left: 0px !important;
    margin: 0 auto !important;
    width: 100vw !important;
    border-radius: 0px !important;
  }
  .container3 {
    width: 100vw !important;
    text-align: center;
    height: fit-content !important;
    margin-top: 1.5rem !important;
  }

  .purchase-button3 {
    position: relative !important;
    margin: 0 auto !important;
    top: 30px !important;
    width: 85vw !important;
    margin-right: -10px !important;
    margin-left: 0px !important;
    margin-bottom: 1.5rem !important;
  }
  .cancel-button2 {
    /* position: relative !important; */
    display: none !important;
  }
  .is-mobile {
    display: block;
  }
  .is-desktop {
    display: none !important;
  }
  .title {
    margin-top: 6rem !important;
    /* margin-bottom: rem !important; */
    text-align: center;
    font-size: 1.2rem !important;
  }
  .titlers {
    margin-top: 0rem !important;
    /* margin-bottom: rem !important; */
    text-align: center;
    font-size: 1.2rem !important;
  }
  .title2 {
    margin-top: 3rem !important;
    margin-bottom: 4rem !important;
    text-align: center;
    font-size: 1.2rem !important;
  }
  .subro {
    margin-top: -3rem !important;
    font-size: 0.85rem !important;
  }
  .subtext {
    margin-bottom: -0.5rem !important;
    margin-top: 1rem !important;
    font-size: 0.7rem !important;
  }
  .titleth {
    font-size: 1.2rem !important;
    margin-bottom: 0.5rem !important;
  }
  .subtitleth {
    font-size: 0.8rem !important;
  }
  .floating-button-pay {
    display: none !important;
  }
  .video-container {
    display: block !important;
    width: 100% !important; /* Cambiado a 100% para dispositivos móviles */
    margin: 0 auto !important;
    padding: 0px !important;
    height: fit-content !important;
    margin-bottom: 0px !important;
  }
  .video {
    max-width: none !important;
    width: 100% !important; /* Cambiado a 100% para dispositivos móviles */
    height: auto !important;
    margin: 0 auto !important;
    padding: 0px !important;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}
