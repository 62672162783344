.container {
  height: 100%;
  width: 100%;
  padding: 0;
  background: url("/public/assets/signature-background.png");
  background-position: center;
  height: 100%;
}

.declarationContainer {
  width: 100%;
  padding: 50px 16px;
  max-width: 623px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.declarationData {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.declarationDataTitle {
  color: #fff;
  font-family: "staatliches";
  font-size: 40px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
  letter-spacing: 0.4px;
}

.declarationDataDescription {
  color: #000;
  font-family: "roboto-regular";
  font-size: 22px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.11px;
  position: relative;
}

.declarationDataDescription::before {
  content: open-quote;
  font-size: 70px;
  display: inline;
  line-height: 1px;
  position: absolute;
  top: 10px;
}

.declarationDataDescription::after {
  content: close-quote;
  font-size: 70px;
  display: inline;
  line-height: 1px;
  margin-right: 0.25em;
  vertical-align: -0.5em;
}

.declarationDataDescription {
  quotes: "\201C""\201D";
  font-size: 24px;
  font-style: italic;
}

.declarationData a {
  width: fit-content;
  border-radius: 13px;
  background: #000;
  padding: 16px 24px;
  color: #fff;
  font-family: "roboto-medium";
  font-size: 24px !important;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}

.declarationContainer img {
  width: 100%;
  height: 632px;
  object-fit: cover;
  border-radius: 12px;
  z-index: 90;
}

.declarationContainer .stamp {
  display: none;
}

.declarationContainer .jefferson {
  width: 100%;
  height: 300px;
  max-width: 408px;
}

@media screen and (min-width: 1312px) {
  .declarationContainer {
    max-width: 1280px;
    margin: 0 auto;
    padding: 88px 0;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    margin-top: 57px;
  }

  .declarationData {
    max-width: 600px;
    gap: 24px;
  }

  .declarationContainer .jefferson {
    width: 100%;
    height: 674px;
    max-width: 408px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0px;
    margin: auto 0;
  }

  .declarationContainer .stamp {
    display: flex;
    width: 299px;
    height: 325px;
    mix-blend-mode: multiply;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: -98px;
    clip: rect(0px, 299px, 227px, 0px);
  }

  .declarationDataTitle {
    font-size: 48px !important;
    letter-spacing: 0.48px;
  }

  .declarationDataDescription {
    font-size: 32px !important;
    line-height: 44px;
  }
}
