.signatureContainer {
  width: 100%;
  padding: 30px;
  border-radius: 15px;
  background: #fff;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.signatureContainer button {
  width: 100%;
  padding: 16px 24px;
  border-radius: 13.788px;
  background: #000;
  text-transform: uppercase;
  color: #fff;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}

.signatureFormInputSection {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.signatureFormInputTitle {
  color: #070505;
  font-family: Staatliches;
  font-size: 48px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.48px;
}

.signatureFormInputDescription {
  color: #424242;
  font-feature-settings: "dnom" on;
  font-family: Roboto;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.signatureBoxContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.signatureBoxTabs {
  width: 100%;
  display: flex;
  gap: 6px;
  justify-content: flex-end;
}

.signatureBoxTabs span {
  padding: 6px;
  color: #000;
  font-family: Roboto;
  font-size: 10.5px !important;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  user-select: none;
  cursor: pointer;
}

.signatureBoxTabs .active {
  border-bottom: 1px solid #000;
}

.padContainer {
  position: relative;
}

.pad {
  width: 100%;
  height: 164px;
  border-radius: 12px;
  border: 2px solid #0c0c0c;
  background: #efefef;
  cursor: url(../cursor.png), url(../cursor.cur), auto;
}

.signaturePad {
  width: 100%;
  height: 164px;
  border-radius: 12px;
  border: 2px solid #0c0c0c;
  background: #efefef;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  user-select: none;
  -webkit-user-select: none;
}

.signaturePad p {
  font-family: Beth Ellen;
  font-size: 24px;
  text-align: center;
  display: -webkit-box;
  max-width: 200px;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.padContainer button {
  position: absolute;
  bottom: 16px;
  right: 16px;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 14px !important;
  width: fit-content;
}

.signatureInputs {
  display: flex;
  flex-direction: column;
  gap: 27px;
}

.formGroup {
  position: relative;
}

.formGroup input {
  max-width: 600px;
  width: 100%;
  padding-bottom: 5px;
  border: none;
  border-bottom: 1px solid #000;
  background-color: transparent;
  color: #000;
  font-size: 16px;
}

.formGroup input:focus {
  outline: none;
  border-bottom: 1px solid #000;
}

.formGroup label {
  position: absolute;
  bottom: calc(15% + 0.25rem);
  left: 0;
  font-size: 1rem;
  color: #000;
  transition: all 0.2s ease-in-out;
  pointer-events: none;
}

.formGroup input:not(:placeholder-shown) + label,
.formGroup input:focus + label {
  transform: translateY(-20px);
  font-size: 13px;
  color: #000;
}

@media screen and (min-width: 1312px) {
  .signatureContainer {
    max-width: 444px;
    padding: 42px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    height: 666px;
  }
}
