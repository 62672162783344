.bannerContainer {
  height: 122px;
  width: 100%;
  background-image: url("../../../../public/assets/banner-divier-image.webp");
  background-position: 60% 0%;
}

@media screen and (min-width: 1312px) {
  .bannerContainer {
    height: 213px;
    background-position: center;
  }
}
