.footerContainer {
  display: flex;
  padding: 24px 16px;
  flex-direction: column;
  height: 100%;
  max-width: 623px;
  margin: 0 auto;
  gap: 16px;
}

.footerTopContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footerMediaContainer {
  display: flex;
  align-items: center;
  gap: 16px;
}

.footerMediaContainer a {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footerTermsContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.footerTermsLinks {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.footerContainer p,
a {
  color: #000;
  text-align: left;
  font-family: "roboto-regular";
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
}

.footerTermsLinks a {
  text-decoration: underline;
}

.footerTermsLinks p {
  font-family: "roboto-medium";
}

@media screen and (min-width: 1312px) {
  .footerContainer {
    width: 100%;
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
  }

  .footerTopContainer {
    justify-content: flex-start;
    gap: 32px;
  }

  .footerTermsContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .footerTermsLinks {
    flex-direction: row;
  }
  .footerContainer p,
  a {
    text-align: center;
  }
}
