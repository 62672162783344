.container {
  height: 100%;
  width: 100%;
  padding: 0;
  background: #1b1936;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.participateContainer {
  position: relative;
  width: 100%;
  padding: 50px 16px;
  max-width: 654px;
  gap: 24px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 10;
}

.participateTopDataContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.participateDataContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: #fff;
}

.participateDataTitle {
  font-family: Staatliches;
  font-size: 40px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
  letter-spacing: 0.4px;
}
.participateDataDescription {
  font-family: "roboto-regular";
  font-size: 22px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.11px;
}

.signImageContainer {
  width: 100%;
  height: 300px;
}

.signImage {
  position: relative;
  height: 300px;
  max-width: 404px;
  left: 50%;
  transform: translateX(-50%);
}

.arrow {
  position: absolute;
  left: -50px;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.signButton {
  width: fit-content;
  border-radius: 13px;
  padding: 16px 24px !important;
  color: #fff;
  font-family: "roboto-medium";
  font-size: 24px !important;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  background: #b22234;
  z-index: 10;
}

.backgroundTextContainer {
  color: rgba(44, 41, 89, 0.7);
  font-family: "staatliches";
  position: absolute;
  bottom: 0;
  left: 16px;
  opacity: 0.23;
  mix-blend-mode: exclusion;
  z-index: 1;
}

.backgroundTextContainer p:first-of-type {
  font-size: 104px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 2.6px;
}
.backgroundTextContainer p:nth-child(2) {
  font-size: 236px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 200px;
  letter-spacing: 6px;
}
.backgroundTextContainer p:nth-child(3) {
  font-size: 104px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 2.603px;
}

.desktopImageContainer {
  display: none;
}

.desktopImageContainerMobile {
  height: 300px;
  width: 100%;
  max-width: 409px;
  position: relative;
}

@media screen and (min-width: 588px) {
  .desktopImageContainerMobile {
    margin: 0 auto;
    scale: 1.2;
  }
}

.declarationImageMobile {
  width: 224px;
  height: 272px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto 0;
  rotate: 7.74deg;
}

.arrowMobile {
  position: absolute;
  left: -50px;
  right: 0;
  top: -16px;
  bottom: 0;
  margin: auto;
}

.signMobile {
  position: absolute;
  bottom: 40px;
  left: 1px;
}

.backgroundTextDesktop {
  display: none;
}

.backgroundText {
  position: absolute;
  color: rgba(44, 41, 89, 0.7);
  font-family: Staatliches;
  bottom: 0;
  left: 0;
  opacity: 0.23;
  mix-blend-mode: exclusion;
}

.backgroundText p:first-child {
  font-size: 104px !important;
  letter-spacing: 2px !important;
  line-height: 90px;
}

.backgroundText p:nth-child(2) {
  font-size: 236px !important;
  letter-spacing: 6px !important;
  line-height: 200px;
}
.backgroundText p:nth-child(3) {
  font-size: 104px !important;
  letter-spacing: 2px !important;
  line-height: 90px;
}

@media screen and (min-width: 1312px) {
  .participateContainer {
    max-width: 1280px;
    margin: 0 auto;
    padding: 187px 0 120px 0;
    height: 1100px;
    justify-content: flex-start;
    gap: 24px;
  }

  .participateTopDataContainer {
    max-width: 624px;
    gap: 32px;
  }

  .backgroundTextContainer {
    display: none;
  }

  .backgroundText {
    display: none;
  }

  .backgroundTextDesktop {
    font-family: Staatliches;
    display: flex;
    bottom: 0;
    position: absolute;
    opacity: 0.23;
    mix-blend-mode: exclusion;
    z-index: 1;
    white-space: nowrap;
    gap: 56px;
  }

  .backgroundTextDesktop p:first-child {
    font-size: calc(99vw / 14 * 2.56) !important;
    line-height: calc(99vw / 14 * 2) !important;
  }
  .backgroundTextDesktop p:nth-child(2) {
    font-size: calc(99vw / 14 * 2.56) !important;
    line-height: calc(99vw / 14 * 2) !important;
  }
  .backgroundTextDesktop p:nth-child(3) {
    font-size: calc(99vw / 14 * 2.56) !important;
    line-height: calc(99vw / 14 * 2) !important;
  }

  .imageContainer {
    display: none;
  }

  .participateDataTitle {
    font-size: 48px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.48px;
  }

  .participateDataDescription {
    font-size: 32px !important;
    font-weight: 400 !important;
    line-height: 44px !important;
    letter-spacing: 0.16px;
  }

  .desktopImageContainer {
    display: flex;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 900px;
    max-width: 1280px;
  }

  .declarationImageDesktop {
    position: absolute;
    top: -26px;
    bottom: 0;
    margin: auto 0;
    right: -36px;
    width: 656px;
    height: 796px;
    rotate: 7deg;
  }

  .arrowDesktop {
    position: absolute;
    left: -118px;
    right: 0;
    bottom: calc(50% - 212px);
    margin: 0 auto;
  }

  .signDesktop {
    width: 280px;
    height: 280px;
    position: absolute;
    left: 40px;
    bottom: 78px;
  }

  .desktopImageContainerMobile {
    display: none;
  }
}
