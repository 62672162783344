@font-face {
  font-family: "roboto-extralight";
  src: local("Roboto-Thin"),
    url("../../public/assets/fonts/Roboto-Thin.ttf") format("truetype");
}
@font-face {
  font-family: "roboto";
  src: local("Roboto-Light"),
    url("../../public/assets/fonts/Roboto-Light.ttf") format("truetype");
}
@font-face {
  font-family: "roboto-medium";
  src: local("Roboto-Medium"),
    url("../../public/assets/fonts/Roboto-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "roboto-regular";
  src: local("Roboto-Regular"),
    url("../../public/assets/fonts/Roboto-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "roboto-extrabold";
  src: local("Roboto-Black"),
    url("../../public/assets/fonts/Roboto-Black.ttf") format("truetype");
}
@font-face {
  font-family: "staatliches";
  src: local("Staatliches-Regular"),
    url("../../public/assets/fonts/Staatliches-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "unifrakturMaguntia";
  src: local("UnifrakturMaguntia-Regular"),
    url("../../public/assets/fonts/UnifrakturMaguntia-Regular.ttf")
      format("truetype");
}
