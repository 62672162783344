h1,
p {
  margin: 0;
}

.container {
  height: 100%;
  width: 100%;
  padding: 0;
}

.againstContainer {
  width: 100%;
  padding: 50px 16px;
  max-width: 623px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.againstTitle {
  color: #000;
  font-family: "staatliches";
  font-size: 40px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.4px;
  text-align: center;
}

.againstItems {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 48px;
}

.againstItem {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
}

.againstItem img {
  height: 100px;
  width: 100px;
  object-fit: contain;
}

.againstItemText {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.againstItemTextTitle {
  color: #000;
  font-family: "roboto-regular";
  font-size: 28px !important;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.28px;
  text-align: center;
}

.againstItemTextDescription {
  color: #5a5a5a;
  text-align: center;
  font-feature-settings: "dnom" on;
  font-family: "roboto-regular";
  font-size: 18px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

@media screen and (min-width: 1312px) {
  .againstContainer {
    max-width: 1280px;
    margin: 0 auto;
    padding: 96px 0;
    gap: 32px;
  }

  .againstItems {
    width: 100%;
    flex-direction: row;
    gap: 87px;
    justify-content: space-between;
  }

  .againstTitle {
    font-size: 48px !important;
    letter-spacing: 0.48px;
  }

  .againstItemTextDescription {
    font-size: 22px !important;
    line-height: 32px;
  }
}
