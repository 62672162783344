h1,
p {
  margin: 0;
}
.container {
  background-color: #1b1936;
  height: 100%;
  width: 100%;
  padding: 0;
}

.heroContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px 16px 50px;
  max-width: 623px;
  margin: 0 auto;
}

.heroData {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.heroDataText {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.heroData img {
  -webkit-filter: grayscale(1) invert(1);
  filter: grayscale(1) invert(1);
  width: 163px;
  height: 59px;
}

.heroData h1 {
  color: #fff;
  font-family: "staatliches";
  font-size: 40px !important;
  font-weight: 400;
  line-height: 46px;
  letter-spacing: 0.2px;
  text-transform: uppercase;
}

.heroData h1 span {
  color: #c53e4f;
}

.heroData p {
  color: #fff;
  font-family: "roboto";
  font-size: 18px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

@media screen and (min-width: 1312px) {
  .heroContainer {
    max-width: 1280px;
    margin: 0 auto;
    padding: 70px 0;
    flex-direction: row;
    justify-content: space-between;
  }

  .heroData {
    max-width: 716px;
    gap: 48px;
  }

  .heroData h1 {
    font-size: 60px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 68px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
  }
  .heroData p {
    font-size: 32px !important;
    line-height: 40px;
  }
}
