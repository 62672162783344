.share-section {
  background-color: rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(10px);
  width: 20vw;
  text-align: center;
  margin-top: 5rem;
  border-radius: 12px;
  padding: 10px;
  margin-bottom: 2rem;
}

.share-button-icon {
  color: white;
  width: 2vw;
  height: 2vw;
  margin: 10px;
}

.share-section h1 {
  color: white;
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 10px;
}

/* Estilos para pantallas pequeñas (mobile) */
@media screen and (max-width: 600px) {
  .titleth {
    font-size: 1.4rem !important;
  }
  .subtitleth {
    width: 90vw !important;
    font-size: 0.9rem !important;
    margin: 0 auto !important;
  }
  .div-firstth {
    margin-top: 2rem !important;
  }
  .gif-div {
    position: relative !important;
    margin-top: 2rem !important;
  }
  .gif {
    width: 85vw !important;
  }
  .find-button {
    max-width: 80vw !important;
    font-size: 0.8rem !important;
  }
  .share-section {
    margin-top: 4rem !important;
    margin-bottom: 6rem !important;
    margin: 0 auto;
    width: 80vw; /* Ancho mayor para pantallas pequeñas */
  }
  .operation{
    margin-bottom: -1rem !important;
    margin-top: 1rem !important;
  }

  .share-button-icon {
    width: 5vw; /* Ajustar el tamaño de los íconos */
    height: 5vw;
  }
  .share-title {
    font-size: 1rem !important;
  }

  .thanks-container {
    flex-direction: column-reverse !important;
  }
}

/* Estilos para pantallas medianas (tablets) */
@media screen and (min-width: 601px) and (max-width: 1024px) {
  .find-button {
    font-size: 1rem !important;
  }
  .share-section {
    width: 60vw; /* Ancho moderado para pantallas medianas */
  }

  .share-title {
    font-size: 1rem !important;
  }

  .share-button-icon {
    width: 4vw; /* Ajustar el tamaño de los íconos */
    height: 4vw;
  }

  .thanks-container {
    flex-direction: column-reverse !important;
  }
}

/* Estilos para pantallas grandes (desktop) */
@media screen and (min-width: 1025px) {
  .share-title {
    font-size: 1.4rem !important;
  }

  .share-section {
    width: 55vw; /* Ancho original para pantallas grandes */
  }

  .share-button-icon {
    width: 2vw; /* Tamaño original de los íconos */
    height: 2vw;
  }

  .social-icon {
    width: 60px !important;
    aspect-ratio: 2/3;
    object-fit: contain;
  }
  .social-icon-mail {
    width: 75px !important;
  }
  .social-icon-tw {
    width: 100px !important;
    position: relative;
    right: -5px;
    margin-left: -5px !important;
  }
}
