.custom-btn{
    border: none !important;
    outline: none; /* Elimina el estilo de enfoque predeterminado */
    box-shadow: none; /* Elimina la sombra de enfoque predeterminada */
}

.custom-btn:focus{
    border: none !important;
}



