.container {
  height: 100%;
  width: 100%;
  padding: 0;
  background-image: url("../../../../public/assets/text-background.webp");
  background-position: center;
}

.carouselContainer {
  padding: 50px 0px 77px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  overflow: hidden;
}

.carouselText {
  display: flex;
  flex-direction: column;
  gap: 12px;
  color: #000;
  text-align: center;
  padding: 0 16px;
}

.carouselTitle {
  color: #b22234;
  font-family: "staatliches";
  font-size: 40px !important;
  font-weight: 400;
  line-height: 50px;
  letter-spacing: 0.4px;
}

.carouselDescription {
  font-family: "roboto-regular" !important;
  font-size: 22px !important;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
}

.carousel {
  position: relative;
  display: flex;
  align-items: center;
  gap: 19px;
  overflow: hidden;
  height: 250px;
  max-width: 815px;
  margin: 0 auto;
  left: -176px;
}

.carouselItem {
  position: relative;
  left: 0;
  flex-shrink: 0;
  height: 200px;
  width: 120px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.carouselImage {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 12px;
}

.carouselSignImage {
  width: 100%;
  height: 94px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carouselSignImage img {
  width: 100%;
  height: 94px;
  object-fit: contain;
}

.youImage {
  position: relative;
  width: 100%;
  height: 150px;
  object-fit: cover;
  top: -20px;
  rotate: 5deg;
}

.youSignImage {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 94px;
}

.carouselContainer a {
  width: fit-content;
  border-radius: 13px;
  background: #b22234;
  padding: 16px 24px;
  color: #fff;
  font-family: "roboto-medium";
  font-size: 24px !important;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  margin: 0 auto;
}

@media screen and (min-width: 600px) {
  .carousel {
    left: 0;
  }
}

@media screen and (min-width: 1312px) {
  .carousel {
    gap: 42px;
    height: 467px;
    max-width: 1650px;
  }

  .carouselContainer {
    padding: 179px 0 154px;
  }

  .carouselItem {
    left: 0;
    height: 400px;
    width: 239px;
  }

  .carouselImage {
    width: 100%;
    height: 298px;
    object-fit: cover;
  }

  .youImage {
    position: relative;
    width: 100%;
    height: 308px;
    overflow: visible;
    object-fit: cover;
    top: -20px;
    rotate: 5deg;
  }

  .youSignImage img {
    width: auto;
    height: 60px;
  }

  .carouselTitle {
    text-align: center;
    font-family: Staatliches;
    font-size: 48px !important;
    line-height: normal;
    letter-spacing: 0.48px;
  }

  .carouselDescription {
    color: #000;
    text-align: center;
    font-family: Roboto;
    font-size: 32px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: normal;
    max-width: 727px;
    margin: 0 auto;
  }
}
