.container {
  height: 100%;
  width: 100%;
  padding: 0;
}

.signerCarouselContainer {
  width: 100vw;
  display: flex;
  flex-direction: column;
  gap: 48px;
  padding: 50px 16px 0px;
  position: relative;
  overflow: hidden;
  align-items: inherit;
}

.signersDataText {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.signersData {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 24px;
  align-items: center;
}

.carouselImagePlaceholderContainer {
  display: none;
  align-items: center;
  justify-content: center;
  box-shadow: 0, 30px 0 15px -10px white inset, 0,
    -30px 0 15px -10px white inset;
}

.signersDataText p {
  color: #000;
  font-family: "staatliches";
  font-size: 40px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 50px; /* 125% */
  letter-spacing: 0.4px;
}

.signersDataText span {
  color: #b22234;
  font-family: "staatliches";
  font-size: 130px;
  font-style: normal;
  font-weight: 400;
  line-height: 110px;
}

.signersData a {
  padding: 16px 24px;
  border-radius: 13.788px;
  background: #000;
  color: #fff;
  font-family: "roboto-medium";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  width: fit-content;
}

.signerCarousel {
  display: flex;
  flex-direction: column;
}

.signerCarouselTitle {
  color: #4c4c4c;
  font-family: "roboto-regular";
  font-size: 32px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-bottom: 1px solid rgba(156, 156, 156, 0.5);
  padding-bottom: 21px;
}

.slide {
  width: 100%;
}

.slideContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.slideContainer img {
  width: 120px;
  height: 120px;
}

.slideContainerSigner {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.slide p {
  color: #2b2b2b;
  font-feature-settings: "dnom" on;
  font-family: Roboto;
  font-size: 24px !important;
  font-style: italic;
  font-weight: 300;
  line-height: 35.5px !important;
}

.slideLocation {
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: fit-content;
}

.slideLocation p {
  color: #000;
  text-align: center;
  font-family: "roboto-medium";
  font-size: 24px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 24px !important;
}

.slideLocation span {
  color: #676767;
  font-family: "roboto-regular";
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
}

.button__bar {
  display: flex !important;
  align-items: center;
  gap: 12px;
  justify-content: center;
  align-items: center;
  padding-top: 16px;
}

.button__bar li {
  margin: 0;
  list-style: none;
  cursor: pointer;
  height: 14px;
}

.button__bar li button {
  border: none;
  background: #b22234;
  color: transparent;
  cursor: pointer;
  height: 14px;
  width: 14px;
  border-radius: 50%;
}

.button__bar li.slick-active button {
  background-color: #1d1b63;
}

.button__bar li button:hover,
.button__bar li button:focus {
  background: #1d1b63;
  outline: 0;
}

@media screen and (min-width: 1312px) {
  .container {
    background-image: url("/public/assets/banner-divier-image-dark.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .signerCarouselContainer {
    max-width: 1280px;
    padding: 0px 0px 0px;
    margin: 0 auto;
    flex-direction: row;
    justify-content: space-between;
  }

  .carouselImagePlaceholderContainer {
    display: flex;
    box-shadow: 30px 0 15px -10px white inset, -30px 0 15px -10px white inset;
    z-index: 2;
    /* width: auto; */
  }

  .signerCarousel {
    gap: 32px;
  }

  .button__bar {
    padding-top: 32px;
  }

  .signersDataText {
    gap: 8px;
  }
  .signersDataText p {
    font-size: 48px !important;
    letter-spacing: 0.48px !important;
    color: #fff;
  }

  .signersData a {
    background: #000;
    color: #fff;
  }

  .signersDataText span {
    font-size: 150px !important;
    line-height: 120px;
  }

  .slideContainer img {
    width: 204px;
    height: 204px;
  }

  .signersData {
    justify-content: center;
    max-width: 381px;
    gap: 57px;
    padding-top: 50px;
  }

  .slideContainerSigner {
    flex-direction: row;
    gap: 51px;
    align-items: center;
  }

  .button__bar {
    justify-content: flex-start;
  }
}
