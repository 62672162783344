.countdownNumberContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.countdownNumberItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: 73px;
  color: #fff;
}

.countdownNumber {
  font-family: "roboto-extrabold";
  font-size: 65px !important;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}
.countdownLabel {
  font-family: "roboto";
  font-size: 22px !important;
  font-style: normal;
  font-weight: 300 !important;
  line-height: 30px;
}

@media screen and (min-width: 1312px) {
  .countdownNumberContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 83px;
  }

  .countdownNumberItem {
    width: 73px;
  }

  .countdownNumber {
    font-size: 70px !important;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }

  .countdownLabel {
    font-size: 31px !important;
    font-style: normal;
    font-weight: 300 !important;
    line-height: normal;
  }
}
