.declaration {
  background: #fefcd7
    url("https://img.freepik.com/fotos-premium/fondo-textura-papel-viejo_118047-1365.jpg?w=2000");
  background-repeat: repeat;
  background-size: cover;
  height: auto;
}

.floating-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: white;
  color: black;
  font-weight: 600;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  z-index: 100;
  border-radius: 12px;
}

.countdown-section3 {
  position: relative;
  /* top: 60px; */
  width: 99vw !important;
  background-color: black; /* Ajusta el color de fondo según sea necesario */
  box-shadow: none !important;
  border-radius: 0 !important;
  z-index: 100;
}

.countdown-container3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}

.countdown-title3 {
  font-size: 1rem !important;
  color: white; /* Ajusta el color del texto según sea necesario */
}

.countdown3 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.countdown-item3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;
}

.countdown-number3,
.countdown-label3 {
  color: white; /* Ajusta el color del texto según sea necesario */
  margin: 0;
}

.countdown-number3 {
  font-size: 1.5rem;
}

.countdown-label3 {
  font-size: 0.8rem !important;
  padding-bottom: 0px;
}

.doi-box {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed rgba(30, 30, 30, 0.3);
  font-family: "Beth Ellen";
  font-size: 16px; /* Ajusta el tamaño de fuente predeterminado según sea necesario */
}


.signature_text {
  font-size: 80cqw;

}

/* Media query para tabletas y dispositivos móviles con pantalla de tamaño mediano */
@media (max-width: 768px) {
  .doi-box {
    font-size: 14px; /* Ajusta el tamaño de fuente para tabletas según sea necesario */
  }


  .text {
    font-size: 0.7rem !important;
  }
}

/* Media query para dispositivos móviles con pantalla pequeña */
@media (max-width: 480px) {
  .doi-box {
    font-size: 10px; /* Ajusta el tamaño de fuente para dispositivos móviles según sea necesario */
  }
}
