@media screen and (max-width: 600px) {
  .raffle-box {
    /* background-color: red !important; */
    max-width: 90vw !important;
    width: 90vw !important;
    margin-top: 1rem !important;
  }

  .raffle-title{
    /* position: absolute !important; */
    /* top: 10rem !important; */
    margin-top: 1rem !important;
    text-align: center !important;
  }
  .raffle-title2{
    /* position: absolute !important; */
    /* top: 10rem !important; */
    margin-top: 1rem !important;
    text-align: center !important;
    margin-bottom: 0px !important;
  }
  .raffle-sub{
    font-size: 0.95rem !important;
    text-align: center !important;
    font-weight: 400 !important;
    margin-bottom: 0rem !important;
  }
  .raffle-link{
    width: 80vw !important;
    margin-bottom: 0rem !important;
  }
  .raffle-howmany{
    font-size: 1rem !important;
  }
  .raffle-prize{
    /* margin-top: 2rem !important; */
    width: 90vw !important;
  }
}
