@media screen and (max-width: 768px) {
  .container {
    flex-direction: column !important;
  }
  .stat {
    /* flex-direction: column !important; */
    width: auto;
    margin: 0px
  }
}

@media screen and (min-width: 768px) {
  .stat {
    /* flex-direction: column !important; */
    width: calc(50% - 30px);
    margin: 0 0 30px 30px;
  }
}
