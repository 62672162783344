.logo-rs {
  /* background-color: red; */
  margin: 0 auto;
  width: 98vw;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 768px){
    .logo-rs{
        width: 92vw !important;
    }
    
}
