.marker-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9rem;
    color: white;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: black;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    border: 3px solid black;
    font-weight: bold;
    transition: all 0.2s ease-in-out;
  }
  
  .marker-icon.active {
    background-color: #55a1e3;
    color: white;
    border: none;
  }
  
  .map-container {
    text-align: center;
    margin: 0 auto;
    width: 85vw !important;
    height: 70vh !important;
    border-radius: 15px;
    filter: grayscale(100%);
  }
  




  /* .map-section {
    width: 100%;
    height: 400px;
    position: relative;
    margin-bottom: 20px;
  } */
  

  
  /* Regla para que el mapa ocupe toda la sección */
  .leaflet-container {
    width: 100%;
    height: 100%;
  }
  
  /* Regla para ajustar el tamaño de los marcadores */
  .marker-icon {
    font-size: 16px;
  }
  
  /* Regla para ajustar el tamaño de los popups */
  .custom-popup {
    max-width: 300px;
  }
  
  @media (max-width: 768px) {
    /* Regla para ajustar la altura de la sección en dispositivos móviles */
    .map-section {
      height: 75vh;
      width: fit-content;
      position: relative;
      left: 0px;
      padding-left: 0px;
      margin-left: 0px;
    }
    
    /* Regla para ajustar el tamaño de los marcadores en dispositivos móviles */
    .marker-icon {
      font-size: 10px;
    }
    
    /* Regla para ajustar el tamaño de los popups en dispositivos móviles */
    .custom-popup {
      max-width: 200px;
    }
  }
  