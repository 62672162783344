@media screen and (max-width: 768px) {
  section {
    padding: 30px;
  }

  h1 {
    font-size: 32px;
    margin-bottom: 20px;
  }

  p {
    font-size: 16px;
    margin-bottom: 20px;
  }

  a {
    font-size: 16px;
  }

  .button-floating {
    display: none !important;
  }
}

@media screen and (max-height: 675px) {
  .homeSec {
    height: 120vh !important;
  }
  .p-mac {
    margin-top: -1rem;
  }

  .typewriter-text {
    margin-top: -3rem !important;
  }
  .process-container {
    /* margin-top: -10rem !important; */
  }
  .process-container2 {
    margin-bottom: -2rem !important;
  }

  .box {
    height: 22vh !important;
    max-height: 22vh !important;
  }
}

@media screen and (max-width: 480px) {
  h1 {
    font-size: 32px !important;
    margin-bottom: 10px;
  }

  p {
    font-size: 14px;
    margin-bottom: 10px;
  }

  a {
    font-size: 14px;
  }
}

@media screen and (max-width: 300px) {
  .countdown-number {
    font-size: 18px !important;
  }
  .countdown-label {
    font-size: 10px !important;
  }
}

@media screen and (max-width: 600px) and (min-width: 301px) {
  .countdown-number {
    font-size: 20px !important;
  }
  .countdown-label {
    font-size: 14px !important;
  }
  /* .countdown-section {
    margin-top: -5rem !important;
    max-height: fit-content;
    padding: 0 !important;
    padding-top: 0px !important;
  } */
}

@media screen and (max-width: 767px) {
  .countdown-container {
    height: 22vh;
  }
  .countdown-section {
    max-height: fit-content;
    padding: 0 !important;
    padding-top: 0px !important;
  }
  .countdown {
    flex-wrap: nowrap !important;
    width: fit-content;
  }
  .countdown-item {
    /* flex-basis: calc(50% - 40px);
    max-width: calc(50% - 40px); */
    /* margin: 20px; */
  }

  .historical-text {
    /* background-color: red !important; */
    max-width: 85vw !important;
    margin-bottom: 1rem !important;
    font-size: 0.85rem !important;
    /* text-align: justify !important; */
  }
}

@media screen and (min-width: 768px) {
  .countdown {
    flex-wrap: wrap;
    width: 100vw !important;
  }
  .countdown-item {
    flex-basis: 15% !important;
    max-width: 15% !important;
    margin: 0px !important;
  }
}

.homeC {
  overflow-x: hidden;
}

.what-section {
  background-color: #fff;
  color: #000;
  font-family: Arial, sans-serif;
  text-align: center;
  padding: 50px;
}

.importance-section {
  background-color: #fff;
  color: #fff;
  font-family: Arial, sans-serif;
  text-align: center;
  padding: 50px;
}

.importance-container {
  margin: 0 auto;
  max-width: 800px;
}

.importance-container h2 {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 30px;
}

.importance-container p {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 30px;
}

@media screen and (max-width: 767px) {
  h2 {
    font-size: 24px !important;
  }

  p {
    font-size: 16px !important;
  }
}

.countdown-section {
  background-color: #fff;
  color: #000;
  font-family: Arial, sans-serif;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
}

.countdown-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  padding: 30px;
}

.countdown-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.countdown {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;
}

.countdown-item {
  flex: 1 1 25%;
  max-width: 25%;
  margin: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.countdown-number {
  font-size: 40px;
  font-weight: bold;
}

.countdown-label {
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.scroll-top-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
}

@media (max-width: 768px) {
  .scroll-top-container {
    bottom: 10px;
    right: 10px;
  }
}

.button-floating {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
  padding: 1rem;
  background-color: black;
  color: white;
  border: none;
  border-radius: 12px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
}

.button-floating:hover {
  background-color: #222222;
}

.button-floating:focus {
  outline: none;
}

.button-floating:active {
  box-shadow: inset 0px 2px 5px rgba(0, 0, 0, 0.25);
}

.stats-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin-top: 30px;
}

@media (max-width: 768px) {
  .intro-stats {
    padding-left: 20px !important;
    padding-right: 20px !important;
    margin: 0 auto;
    margin-bottom: 3rem;
  }
}

@media screen and (max-width: 767px) {
  .stats-grid {
    max-width: 100vw !important;
    grid-template-columns: 1fr !important;
    grid-gap: 10px;
    margin: 0 30px;
  }

  .stats-item h3 {
    font-size: 14px !important;
  }

  .stats-item p {
    font-size: 13px !important;
  }
}

.hero-item {
  font-size: 16px;
  text-align: center;
  margin: 30px 15px;
}

.sacrifice-section {
  background-color: #222;
  color: #fff;
  padding: 50px 15px; /* Cambiar el padding a 15px en vez de 50px */
}

.sacrifice-container {
  max-width: 100%; /* Hacer que el contenedor sea del 100% del ancho */
  padding: 0 15px; /* Añadir un padding de 15px en los costados */
  text-align: center;
}

.sacrifice-container h2 {
  font-size: 24px; /* Cambiar el tamaño de fuente para móviles */
  margin-bottom: 20px;
}

.sacrifice-container p {
  font-size: 16px; /* Cambiar el tamaño de fuente para móviles */
  line-height: 1.3; /* Reducir el line-height para móviles */
}

@media (max-width: 768px) {
  .sacrifice-container h2 {
    font-size: 22px !important;
  }

  .sacrifice-container p {
    font-size: 16px !important;
  }
}

.video-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
  justify-content: center;
  /* align-items: center;
  flex-wrap: wrap;
  max-width: 1000px;
  margin: 0 auto;
  margin-top: 40px; */
}

.video-item {
  width: 100%;
  max-width: 600px;
  /* margin: 20px; */
  /* margin-right: 20px; */
  margin-bottom: 20px;
  width: 480px;
  height: 270px;
}

@media (min-width: 768px) {
  .video-wrapper {
    flex-direction: row;
  }

  .video-item {
    width: 50%;
    margin: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .video-wrapper {
    display: block;
  }

  .video-item {
    width: 100%;
    margin-bottom: 30px;
  }

  .video-item iframe {
    width: 100%;
  }

  .video-container {
    padding: 50px 15px;
  }
}

.video-section {
  background-color: #ffffff;
  padding: 80px 0;
  text-align: center;
}

.video-title {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 40px;
}

.video-content {
  max-width: 800px;
  margin: 0 auto;
}

.video-content h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.video-content p {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 20px;
}

.video-item:last-child {
  margin-right: 0;
}

.video-item iframe {
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 8px;
}

@media only screen and (max-width: 767px) {
  .footer-container {
    justify-content: center;
    text-align: center;
    padding: 50px 15px;
  }

  .footer-logo {
    margin-bottom: 40px;
  }

  .footer-social {
    flex-direction: column;
    margin-bottom: 40px;
  }

  .footer-additional {
    margin-top: 40px;
    text-align: center;
    padding: 50px 15px;
  }

  .footer-additional-container {
    justify-content: center;
  }
}

/* Estilo para el efecto hover */
footer a:hover {
  color: white;
  text-decoration: underline !important;
}

.hero-carousel-wrapper {
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 20px;
}

.slick-slide > img {
  width: 100%;
}

.slick-center h2,
.slick-center p {
  color: #fff;
}

@media (max-width: 768px) {
  .heroC {
    position: relative !important;
    padding: 50px;
    text-align: center;
    height: fit-content !important;
  }

  .heroImage {
  }

  .heroName {
    font-size: 0.8rem !important;
  }

  .heroP {
    font-size: 0.8rem !important;
  }
}

.heroC {
  padding: 50px;
  text-align: center;
}

.heroSign {
  margin-top: -20px;
  padding-top: 15px;
  margin: 0 auto;
  height: 70px;
  width: 180px;
}

.heroImage {
  height: 350px;
  width: 300px;
  object-fit: cover;
  margin: 0 auto;
  border-radius: 100px;
}

.heroName {
  margin-top: 0.3rem;
  font-weight: bold;
  font-size: 1rem;
}

.heroP {
  font-size: 0.9rem;
  width: 100%;
  /* background-color: red; */
  margin-top: 0.5rem;
}

.flag-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  background-color: #f5f5f5;
  color: #333;
}

.flag-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.flag-card {
  margin: 10px;
  text-align: center;
  max-width: 300px;
}

.flag-card img {
  width: 100%;
  height: auto;
  border-radius: 4px;
  margin-bottom: 15px;
}

.flag-card h2 {
  font-weight: bold;
  margin-bottom: 3px;
}

.flag-card p {
  width: 100%;
  text-align: justify;
  margin-bottom: 20px;
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .box {
    width: 20vw !important;
  }

  .p-process {
    font-size: 0.7rem !important;
  }
}
@media screen and (max-height: 722px) {
  .modal-p {
    font-size: 0.65rem !important;
  }
  .modal-img {
    width: 5rem !important;
    height: 5rem !important;
  }
}

@media screen and (max-width: 768px) {
  .flag-container {
    flex-direction: row;
    max-width: 1000px;
    margin: 0 auto;
  }

  .flag-card {
    flex: 1;
    margin: 10px;
    max-width: none;
  }

  .flag-disposition {
    flex-direction: column !important;
  }

  .process-container {
    height: fit-content !important;
  }
  .process-container2 {
    height: fit-content !important;
  }

  .homeSec {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
    height: fit-content !important;
    /* margin-bottom: 6rem !important; */
  }

  .img-process {
    /* display: none; */
  }

  .titleDec {
    margin-top: -1rem !important;
    font-size: 22px !important;
  }

  .p-process {
    width: 100%;
    text-align: center;
    font-size: 0.7rem !important;
  }

  .btn-process {
    font-size: 0.9rem !important;
  }

  .box {
    width: 40vw !important;
    margin: 0 auto;
    margin-left: 10px !important;
    margin-right: 10px !important;
    margin-bottom: 2.7rem;
    height: 20vh;
  }

  .modal {
    width: 85vw !important;
    height: fit-content;
    margin: 0 auto;
    position: relative;
    top: 13% !important;
  }

  .modal-img {
    width: 6rem !important;
    height: 6rem !important;
  }

  .modal-h2 {
    font-size: 1rem !important;
  }

  .modal-p {
    font-size: 0.7rem !important;
  }

  .stack {
    margin-top: -1rem !important;
  }

  .thanks-container {
    flex-direction: column !important;
    margin-top: -2rem !important;
    margin: 0 auto !important;
  }

  .sec-pad {
    padding: 20px 10px !important;
  }

  .thanks-sec {
    margin-right: 0px !important;
    width: 85vw !important;
    height: fit-content !important;
    margin-left: 0px !important;
    margin-bottom: 15px !important;
  }

  .thanks-sec2 {
    margin-top: 3rem;
    margin-right: 0px !important;
    width: 85vw !important;
    height: fit-content !important;
    margin-left: 0px !important;
    margin-bottom: 15px !important;
  }

  .sec-title {
    font-size: 1.2rem !important;
    width: 100%;
  }

  .sec-title2 {
    font-size: 1.5rem !important;
    width: 100%;
  }
}

@keyframes typewriter {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes blinkTextCursor {
  from {
    border-right-color: transparent;
  }
  to {
    border-right-color: inherit;
  }
}

.typewriter-text {
  overflow: hidden; /* Ocultar el contenido que sobresale */
  border-right: 0.2em solid; /* Cursor simulado */
  white-space: nowrap; /* Evitar saltos de línea */
  animation: typewriter 3s steps(40, end),
    blinkTextCursor 0.5s step-end infinite; /* Animación de cursor parpadeante */
}

.modal-content {
  animation: modalOpen 0.3s ease-in-out;
}

@keyframes modalOpen {
  0% {
    transform: scale(0.7);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
