.bannerTextContainer {
  height: 42px;
  width: 100%;
  background-image: url("../../../../public/assets/banner-divider-texture.webp");
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bannerTextContainer p {
  color: #2b2626;
  font-family: UnifrakturMaguntia;
  font-size: 16px !important;
  letter-spacing: -0.753px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.bannerTextContainer p span {
  font-size: 9.135px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.365px;
}

@media screen and (min-width: 1524px) {
  .bannerTextContainer {
    height: 120px;
  }

  .bannerTextContainer p {
    font-size: 72.719px !important;
    letter-spacing: -2.909px;
  }

  .bannerTextContainer p span {
    font-size: 35.304px;
    letter-spacing: -1.412px;
  }
}
